import Board from './Components/Board'

function App() {
  return (
    <>
      <Board />
    </>
  )
}

export default App
